/**
* Modifications for Quantum Automotive
**/

/* LOGIN */
.quantum-logo {
    width: 80px;
}

.quantum-spinner-inside-logo {
    margin-left: 23px;
    position: relative;
    top: -86px;
    left: 7px;
    width: 20px !important;
    height: 20px !important;
}

.bg-shape {
    background-color: #2ea1a8 !important;
}

.bg-shape:before, .bg-shape:after {
    background-image: linear-gradient(-45deg, rgb(0, 168, 178), #007980) !important;
}

.text-success {
    color: #009b87 !important;
}

.login-dark-toggle {
    position: absolute;
    right: 17px;
    top: 19px;
}

.react-select__control .react-select__multi-value__label {
    font-size: 14px !important;
}

/* ANIMATION */
@keyframes rotateAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotate-endlessly {
    animation: rotateAnimation 7s linear infinite;
}

.admin-ticket {
    padding-top: 0 !important;
}